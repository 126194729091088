import React, { useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
function ChooseCampusNClass() {
  const [key, setKey] = useState('tab2')
  return (
    <>
      {" "}

     

      <div className="campus-classes-selection-page">
        <section className="campus-classes-sec grey-back">
          <div className="campus-classes-inner container">
            <div className="font-30 text-center pb-2">
              Choose your On Campus Classes
            </div>
            <div className="xs-heading">
              You may attend classes at different Matrix campuses.
            </div>
            <div className="tab-box">
              <Tabs className="Tabs" activeKey={key} onSelect={(k) => setKey(k)}>
                <TabList className="tab-course">
                  <Tab className="tablinks" style={{ color: "red" }} eventKey="tab1">Chatswood</Tab>
                  <Tab className="tablinks" eventKey="tab2">Epping</Tab>
                  <Tab className="tablinks" eventKey="tab3"> Huirstville</Tab>
                  <Tab className="tablinks" style={{ color: "red" }} eventKey="tab1">Parramatta</Tab>
                  <Tab className="tablinks" eventKey="tab2">Strathfield</Tab>
                  <Tab className="tablinks" eventKey="tab3">Sydney City</Tab>
                </TabList>
                <TabPanel>
                  <div className="title-hd">Chatswood</div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="flush-headingOne">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseOne"
                        aria-expanded="false"
                        aria-controls="flush-collapseOne"
                      >
                        Year 8 English-Term Course
                      </button>
                    </h2>
                    <div
                      id="flush-collapseOne"
                      className="accordion-collapse collapse"
                      aria-labelledby="flush-headingOne"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div className="accordion-body">
                        Placeholder content for this accordion, which is
                        intended to demonstrate the
                        <code>.accordion-flush</code> className. This is the second
                        item's accordion body. Let's imagine this being filled
                        with some actual content.
                      </div>
                    </div>
                  </div>
                </TabPanel>

                <TabPanel>
                  <div className="title-hd">Epping</div>

                  <div className="accordion-item">

                    <h2 className="accordion-header" id="flush-headingtwo">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseTwo"
                        aria-expanded="false"
                        aria-controls="flush-collapseTwo"
                      >
                        Year 8 English-Term Course
                      </button>
                    </h2>
                    <div
                      id="flush-collapseTwo"
                      className="accordion-collapse collapse"
                      aria-labelledby="flush-headingTwo"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div className="accordion-body">
                        <div className="acc-check-box">
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value=""
                              id="flexCheckDefault"
                            />
                            <label
                              className="form-check-label"
                              for="flexCheckDefault"
                            >
                              <div className="acdn-time">Thursday,6:10pm-7:40pm</div>
                              <div className="acdn-date">
                                12 Oct-7 Dec 2023, Epping Journalism and
                                Discursive Writing
                              </div>
                              <div className="bg-grey">ER21</div>
                            </label>
                          </div>

                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value=""
                              id="flexCheckDefault"
                            />
                            <label
                              className="form-check-label"
                              for="flexCheckDefault"
                            >
                              <div className="acdn-time">Thursday,6:10pm-7:40pm</div>
                              <div className="acdn-date">
                                12 Oct-7 Dec 2023, Epping Journalism and
                                Discursive Writing
                              </div>
                              <div className="bg-grey">ER21</div>
                            </label>
                          </div>

                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value=""
                              id="flexCheckDefault"
                            />
                            <label
                              className="form-check-label"
                              for="flexCheckDefault"
                            >
                              <div className="acdn-time">Thursday,6:10pm-7:40pm</div>
                              <div className="acdn-date">
                                12 Oct-7 Dec 2023, Epping Journalism and
                                Discursive Writing
                              </div>
                              <div className="bg-grey">ER21</div>
                            </label>
                          </div>

                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value=""
                              id="flexCheckDefault"
                            />
                            <label
                              className="form-check-label"
                              for="flexCheckDefault"
                            >
                              <div className="acdn-time">Thursday,6:10pm-7:40pm</div>
                              <div className="acdn-date">
                                12 Oct-7 Dec 2023, Epping Journalism and
                                Discursive Writing
                              </div>
                              <div className="bg-grey">ER21</div>
                            </label>
                          </div>

                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value=""
                              id="flexCheckDefault"
                            />
                            <label
                              className="form-check-label"
                              for="flexCheckDefault"
                            >
                              <div className="acdn-time">Thursday,6:10pm-7:40pm</div>
                              <div className="acdn-date">
                                12 Oct-7 Dec 2023, Epping Journalism and
                                Discursive Writing
                              </div>
                              <div className="bg-grey">ER21</div>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </TabPanel>
                <TabPanel>
                  <div className="title-hd">Huirstville</div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="flush-headingOne">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseOne"
                        aria-expanded="false"
                        aria-controls="flush-collapseOne"
                      >
                        Year 8 English-Term Course
                      </button>
                    </h2>
                    <div
                      id="flush-collapseOne"
                      className="accordion-collapse collapse"
                      aria-labelledby="flush-headingOne"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div className="accordion-body">
                        Placeholder content for this accordion, which is
                        intended to demonstrate the
                        <code>.accordion-flush</code> className. This is the second
                        item's accordion body. Let's imagine this being filled
                        with some actual content.
                      </div>
                    </div>
                  </div>
                </TabPanel>
                <TabPanel>
                  <div className="title-hd">Parramatta</div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="flush-headingOne">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseOne"
                        aria-expanded="false"
                        aria-controls="flush-collapseOne"
                      >
                        Year 8 English-Term Course
                      </button>
                    </h2>
                    <div
                      id="flush-collapseOne"
                      className="accordion-collapse collapse"
                      aria-labelledby="flush-headingOne"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div className="accordion-body">
                        Placeholder content for this accordion, which is
                        intended to demonstrate the
                        <code>.accordion-flush</code> className. This is the second
                        item's accordion body. Let's imagine this being filled
                        with some actual content.
                      </div>
                    </div>
                  </div>
                </TabPanel>
                <TabPanel>
                  <div className="title-hd">Strathfield</div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="flush-headingOne">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseOne"
                        aria-expanded="false"
                        aria-controls="flush-collapseOne"
                      >
                        Year 8 English-Term Course
                      </button>
                    </h2>
                    <div
                      id="flush-collapseOne"
                      className="accordion-collapse collapse"
                      aria-labelledby="flush-headingOne"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div className="accordion-body">
                        Placeholder content for this accordion, which is
                        intended to demonstrate the
                        <code>.accordion-flush</code> className. This is the second
                        item's accordion body. Let's imagine this being filled
                        with some actual content.
                      </div>
                    </div>
                  </div>
                </TabPanel>
                <TabPanel>
                  <div className="title-hd">Sydney City</div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="flush-headingOne">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseOne"
                        aria-expanded="false"
                        aria-controls="flush-collapseOne"
                      >
                        Year 8 English-Term Course
                      </button>
                    </h2>
                    <div
                      id="flush-collapseOne"
                      className="accordion-collapse collapse"
                      aria-labelledby="flush-headingOne"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div className="accordion-body">
                        Placeholder content for this accordion, which is
                        intended to demonstrate the
                        <code>.accordion-flush</code> className. This is the second
                        item's accordion body. Let's imagine this being filled
                        with some actual content.
                      </div>
                    </div>
                  </div>
                </TabPanel>
              </Tabs>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default ChooseCampusNClass;
