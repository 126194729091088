import React, { useState } from "react";
import "./enroll.css";
import { Wizard, useWizard } from "react-use-wizard";
import {
  ChooseCampusNClass,
  CourseSelection,
  EnrollmentSummary,
  FeeSummary,
  Footer,
  Header,
  Login,
  PaymentConfirmation,
  Signup,
  StudentType,
} from "../../components";
const EnrollPage = () => {
  const [newStudent, setNewStudent] = useState("");

  return (
    <>
      <Header />
      {newStudent === "" ? (
        <StudentType newStudent={newStudent} setNewStudent={setNewStudent} />
      ) : (
        ""
      )}

      {newStudent ? (
        <Wizard>
          <Step3 />
          <Step4 />
          <Step5 />
          <Step6 />
          <Step7 />
        </Wizard>
      ) : newStudent !== "" ? (
        <Wizard>
          <Step2 />
        </Wizard>
      ) : (
        ""
      )}

      <Footer />
    </>
  );
};

const Step1 = ({ newStudent, setNewStudent }) => {
  const { handleStep, previousStep, nextStep } = useWizard();

  // Attach an optional handler
  handleStep(() => { });

  return (
    <>
      <StudentType newStudent={newStudent} setNewStudent={setNewStudent} />
      <button onClick={() => previousStep()}>Previous ⏮️</button>
      <button onClick={() => nextStep()}>Next ⏭</button>
    </>
  );
};

const Step2 = () => {
  const { handleStep, previousStep, nextStep } = useWizard();

  // Attach an optional handler
  handleStep(() => { });

  return (
    <>
      <Login />
      <div className="prev-next">
        <button onClick={() => previousStep()}>Previous</button>
        <button onClick={() => nextStep()}>Next</button>
      </div>
    </>
  );
};
const Step3 = () => {
  const { handleStep, previousStep, nextStep } = useWizard();
  const [inputs, setInputs] = useState('')
  // Attach an optional handler
  handleStep(() => { });

  return (
    <>
      <Signup input={setInputs} />
      <div className="prev-next">
        <button onClick={() => previousStep()}>Previous</button>
        <button onClick={() => nextStep()}>Next</button>
      </div>
    </>
  );
};

const Step4 = () => {
  const { handleStep, previousStep, nextStep } = useWizard();

  // Attach an optional handler
  handleStep(() => { });

  return (
    <>
      <CourseSelection />
      <div className="prev-next">
        <button onClick={() => previousStep()}>Previous</button>
        <button onClick={() => nextStep()}>Next</button>
      </div>
    </>
  );
};

const Step5 = () => {
  const { handleStep, previousStep, nextStep } = useWizard();

  // Attach an optional handler
  handleStep(() => { });

  return (
    <>
      <ChooseCampusNClass />
      <div className="prev-next">
        <button onClick={() => previousStep()}>Previous</button>
        <button onClick={() => nextStep()}>Next</button>
      </div>
    </>
  );
};

const Step6 = () => {
  const { handleStep, previousStep, nextStep } = useWizard();

  // Attach an optional handler
  handleStep(() => { });

  return (
    <>
      <EnrollmentSummary />
      <div className="prev-next">
        <button onClick={() => previousStep()}>Previous</button>
        <button onClick={() => nextStep()}>Next</button>
      </div>
    </>
  );
};

const Step7 = () => {
  const { handleStep, previousStep, nextStep } = useWizard();

  // Attach an optional handler
  handleStep(() => { });

  return (
    <>
      <FeeSummary />
      <div className="prev-next">
        <button onClick={() => previousStep()}>Previous</button>
        <button onClick={() => nextStep()}>Next</button>
      </div>
    </>
  );
};
export default EnrollPage;
