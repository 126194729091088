import React from "react";
import "./aboutUs.css";
import {
  AboutUsSection,
  CommonBanner,
  Counter,
  Footer,
  FreeAssessment,
  Header,
  OfferSection,
  OurVision,
} from "../../components";
import images from "../../assets";
const dataToSend = {
  bannerImg: images["aboutbg"],
  bannerHeading: "About Us",
  path: [
    {
      link: "/",
      name: "About Us",
    },
  ],
};

function AboutUs() {
  return (
    <div class="about-page">
      <Header />
      <CommonBanner {...dataToSend} />
      <AboutUsSection />
      <Counter />
      <FreeAssessment />
      <OfferSection />
      <OurVision />
      <Footer />
    </div>
  );
}

export default AboutUs;
