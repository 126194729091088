import React from "react";
import images from "../../../assets";

function BannerBottom() {
   //const images = useImageImport();
  return (
    <section class="banner-bottom-sec">
      <div class="banner-bot-inner container">
        <div class="banner-bot-grid">
          <div class="banner-bot-box">
            <div class="top-title">Hybrid Learning</div>
            <div class="bot-para">
              Hybrid learning implements synchronous lessons taught
              simultaneously in-person and online.
            </div>
          </div>

          <div class="banner-bot-box">
            <div class="top-title">Top Materials</div>
            <div class="bot-para">
              All materials are designed and prepared by a team of highly
              qualified and experienced educators.
            </div>
          </div>

          <div class="banner-bot-box">
            <div class="top-title">Best Teachers</div>
            <div class="bot-para">
              Qualified and experienced teachers ensure students enjoy their
              studies & achieve their ambitions
            </div>
          </div>
        </div>
      </div>
      <div class="side-icon">
        <img {...images["icon-paper"]} />
      </div>
    </section>
  );
}

export default BannerBottom;
