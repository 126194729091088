import React from "react";
import "./courses-details.css";
import {
  CommonBanner,
  CourseDetailSection,
  Footer,
  Header,
  SearchSimilarSIdeBar,
} from "../../components";

import images from "../../assets";
const dataToSend = {
  bannerImg: images["courses-detail"],
  bannerHeading: "Creative English & Reading",
  path: [
    {
      link: "/our-courses",
      name: "Our Courses",
    },
    {
      link: "#",
      name: "Creative English & Reading",
    },
  ],
};
function CourseDetails() {
  return (
    <div class="courses-detail-page">
      <Header />
      <CommonBanner {...dataToSend} />
      <div class="center-content container">
        <CourseDetailSection />
        <SearchSimilarSIdeBar />
      </div>
      <Footer />
    </div>
  );
}

export default CourseDetails;
