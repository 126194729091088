import React from "react";

function FeeSummary() {
  return (
    <div class="fee-summary-page">
      <section class="fee-summary-sec grey-back">
        <div class="fee-summary-inner container">
          <div class="font-30 text-center pb-2">Fee Summary</div>
          <div class="fee-sum-content">
            <div class="fee-sum-lft">
              <div class="xs-heading">Have a coupon?</div>
              <div class="d-flex">
                <div class="input-group w-auto">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="#3643645214523"
                    aria-label="Example input"
                    aria-describedby="button-addon1"
                  />
                  <button
                    class="btn btn-primary"
                    type="button"
                    id="button-addon1"
                    data-mdb-ripple-color="dark"
                  >
                    Apply Coupon
                  </button>
                </div>
              </div>
            </div>

            <div class="fee-sum-rt">
              <div class="xs-heading">
                <sup>*</sup>Important
              </div>
              <div class="fee-sum-list">
                <ol type="1">
                  <li>
                    You can confirm all your selected classes and materials in
                    your cart
                  </li>
                  <li>
                    The classes and materials in your cart will be retained only
                    for 7 days 3.
                  </li>
                  <li>
                    You can put as many classes and materials as you wish in
                    your cart, and then can pay for them altogether
                  </li>
                </ol>
              </div>
            </div>
          </div>

          <div class="cart-total">
            <div class="font-30 pb-2">Cart totals</div>

            <div class="cart-total-box">
              <div class="cart-total-box-inner">
                <div class="total-lf"> Subtotal</div>
                <div class="total-rt"> $90</div>
              </div>
            </div>

            <div class="cart-total-box">
              <div class="cart-total-box-inner">
                <div class="total-lf"> Discount</div>
                <div class="total-rt"> -$25</div>
              </div>
            </div>

            <div class="cart-total-box border-0">
              <div class="cart-total-box-inner">
                <div class="total-lf"> Total</div>
                <div class="total-rt"> $60</div>
              </div>
            </div>

            <div class="cart-total-btn">
              <div class="sap-red-btn">
                <a href="">Proceed to checkout</a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default FeeSummary;
