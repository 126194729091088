import React from "react";

function ContactUsMapView() {
  return (
    <section class="cont-map-sec">
      <div class="cont-map-inner container">
        <div class="cont-map-con">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d13212548.36105523!2d-105.58221940281132!3d36.0515433423406!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x54eab584e432360b%3A0x1c3bb99243deb742!2sUnited%20States!5e0!3m2!1sen!2sin!4v1690353648300!5m2!1sen!2sin"
            width="1000"
            height="450"
            style={{ border: 0 }}
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
    </section>
  );
}

export default ContactUsMapView;
