import React, { useState, useEffect } from "react";
import { apiRequest } from '../../../services';
import { Link } from "react-router-dom";
import { Card } from "../../Small-Components";
import images from "../../../assets";
import image1 from "../../../assets/images/cag1.png";
import image2 from "../../../assets/images/cag2.png";
import image4 from "../../../assets/images/cag4.png";
import image5 from "../../../assets/images/cag5.png";
import image6 from "../../../assets/images/cag6.png";
import image3 from "../../../assets/images/skill23.png";

function CardData({ cardType }) {
  const [course, setCourse] = useState([]);

  useEffect(() => {
    // fetch list of blogs.
    fetchcourse();
  }, []);

  //  get grades 
  const fetchcourse = async () => {
    try {
      const response = await apiRequest('http://65.1.219.190:4040/api/courses-all', 'GET');
      console.log('data', response);
      setCourse(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };




  cardType = "course";
  const cardData = [
    { id: "cag1", title: "Writing" },
    { id: "cag2", title: "Creative English & Reading" },
    { id: "cag1", title: "Mathematical Reasoning Skills" },
    { id: "cag4", title: "Thinking Skills & General Ability" },
    { id: "cag5", title: "Comprehensive Course" },
    { id: "cag6", title: "Trial Tests" },
  ];
  return (
    <>
      <section class="course-cg-sec">
        <div class="course-cg-inner container">
          <div class="course-cg-con">
            <div class="xs-heading" data-aos="fade-left">
              Course Categories
            </div>
            <div class="lg-heading" data-aos="fade-right">
              Pick a course to get started your study
            </div>
            <div className="cag-grid">
            {/* <div class="cag-box"><div class="cag-img"><img src={image3} /></div><div class="cag-bot"><div class="cag-title">Writing</div><div class="cag-combo-btn"><div class="sap-black-btn"><a href="/our-branches">Enroll Now</a></div><div class="cag-rt-btn"><a href="/course-detail">View More</a></div></div></div></div>
            <div class="cag-box"><div class="cag-img"><img src={image1} /></div><div class="cag-bot"><div class="cag-title">Creative English & Reading</div><div class="cag-combo-btn"><div class="sap-black-btn"><a href="/our-branches">Enroll Now</a></div><div class="cag-rt-btn"><a href="/course-detail">View More</a></div></div></div></div>
            <div class="cag-box"><div class="cag-img"><img src={image2} /></div><div class="cag-bot"><div class="cag-title">Mathematical Reasoning Skills</div><div class="cag-combo-btn"><div class="sap-black-btn"><a href="/our-branches">Enroll Now</a></div><div class="cag-rt-btn"><a href="/course-detail">View More</a></div></div></div></div>
            <div class="cag-box"><div class="cag-img"><img src={image4} /></div><div class="cag-bot"><div class="cag-title">Thinking Skill & General Ability</div><div class="cag-combo-btn"><div class="sap-black-btn"><a href="/our-branches">Enroll Now</a></div><div class="cag-rt-btn"><a href="/course-detail">View More</a></div></div></div></div>
            <div class="cag-box"><div class="cag-img"><img src={image5} /></div><div class="cag-bot"><div class="cag-title">Comprehensive Course</div><div class="cag-combo-btn"><div class="sap-black-btn"><a href="/our-branches">Enroll Now</a></div><div class="cag-rt-btn"><a href="/course-detail">View More</a></div></div></div></div> */}
            {/* <div key={course.id} className="cag-box">
                  <div className="cag-img">
                    <img src={image6} />
                  </div>
                  <div className="cag-bot">
                    <div className="cag-title">Trial Tests</div>
                    <div className="cag-combo-btn">
                      {cardType.toLowerCase() == "course" ? (
                        <>
                          <div className="sap-black-btn">
                            <Link to="/our-branches">Enroll Now</Link>
                          </div>
                          <div className="cag-rt-btn">
                            <Link to="/course-detail">View More</Link>
                          </div>
                        </>
                      ) : cardType.toLowerCase() == "blog" ? (
                        <div class="cag-date">16 November 2023</div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div> */}
              {course.map((course) => (
                <div key={course.id} className="cag-box">
                  <div className="cag-img">
                    <img src={course.image ? course.image : `/static/media/cag2.b998215fed59093c832f.png`} />
                  </div>

                  <div className="cag-bot">
                    <div className="cag-title">{course.name}</div>
                    <div className="cag-combo-btn">
                      {cardType.toLowerCase() == "course" ? (
                        <>
                          <div className="sap-black-btn">
                            <Link to="/our-branches">Enroll Now</Link>
                          </div>
                          <div className="cag-rt-btn">
                            <Link to="/course-detail">View More</Link>
                          </div>
                        </>
                      ) : cardType.toLowerCase() == "blog" ? (
                        <div class="cag-date">16 September 2022</div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div class="cag-foot">
              <div class="sap-black-btn">
                <Link to="/our-courses">View all Courses</Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default CardData;
