import React from "react";

function PreviousNextBtn() {
  return (
    <div class="combo-next-prevbtn container">
      <div class="sap-black-btn" data-aos="fade-left">
        <a href="">
          <i class="fa-solid fa-arrow-left"></i>Previous
        </a>
      </div>
      <div class="sap-black-btn" data-aos="fade-left">
        <a href="">
          Next<i class="fa-solid fa-arrow-right"></i>
        </a>
      </div>
    </div>
  );
}

export default PreviousNextBtn;
