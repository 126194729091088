import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { apiRequest } from '../../../services';
// import { apiRequest } from 'services';
function Login() {
  const [message, setMessage] = React.useState(false);
  const [inputs, setInputs] = useState({
    email: '',
    password: ''
  });

  const handleChange = (e) => {
    let { name, value } = e.target;

    setInputs((prev) => ({ ...prev, [name]: value }));
  };


  const handleSubmit = async () => {
    try {
      const response = await apiRequest('http://13.200.114.252:4040/api/student/student-login', 'POST', inputs);
      console.log('Response:', response);
      if (response.message == 'Invalid Credentials') {
        setMessage(response.message)
        setTimeout(() => {
          setMessage(false);
        }, 3000)
      }
      else {
        // return <Home />;
      }
      setInputs({
        email: '',
        password: ''
      });
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };
  return (
    <>
      <div class="current-student-page">
        <section class="form-sec grey-back">
          <div class="form-inner container">
            {/* <form>
              <div class="xs-heading pb-4">Welcome Back</div>

              <div class="row">
                <div class="col-12 col-sm-6">
                  <div class="form-group">
                    <label>
                      User Name(Id)<sup>*</sup>
                    </label>
                    <span class="fa-icon">
                      <i class="fa-regular fa-user"></i>
                    </span>
                    <input
                      name="email"
                      type="text"
                      class="form-control"
                      placeholder="User ID"
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      value={inputs.email}
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div class="col-12 col-sm-6">
                  <div class="form-group">
                    <label>
                      {" "}
                      Password<sup>*</sup>
                    </label>
                    <span class="fa-icon">
                      <i class="fa-solid fa-lock"></i>
                    </span>
                    <input
                    name='password'
                      type="nubmer"
                      class="form-control"
                      placeholder="Password"
                      value={inputs.password}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
              <h7>{message}</h7>
              <div class="set-btn" onClick={handleSubmit}>
                <span class="sap-red-btn">{
                  
                }
                  <Link >Next</Link>
                </span>
              </div>
              
            </form> */}
            <div class="action-sec2">
              <a target="_blank" href="http://61.246.113.222:3002/login">
                Click To Login
              </a>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default Login;
