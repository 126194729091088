import React from "react";
import OwlCarousel from "react-owl-carousel";
const options = {
  autoplay: true,
  loop: true,
  margin: 10,
  responsiveClass: true,
  dots: true,
  nav: true,
  responsive: {
    0: {
      items: 1,
      nav: true,
      dotsEach: 4,
    },
    600: {
      items: 2,
      nav: true,
      dotsEach: 3,
    },
    1000: {
      items: 2,
      nav: true,
      loop: false,

    },
  },
}
function CourseSelection() {
  return (
    <>
      <div class="course-selection-page">
        <section class="course-selection-sec grey-back">
          <div class="course-selection-inner container">
            <div class="font-30 text-center pb-4">
              Which Year 8 course would you like to enrol in?
            </div>
            <OwlCarousel class="owl-carousel" data-aos="fade-left" options {...options}>
              <div class="owl-slide">
                <div class="course-selec-outer active-cs">
                  <div class="course-selec-hd">Matrix+</div>
                  <div class="course-selec-txt">On Demand Learning</div>
                </div>
              </div>

              <div class="owl-slide">
                <div class="course-selec-outer">
                  <div class="course-selec-hd"></div>
                  <div class="course-selec-txt">On Campus 9 Weekly Lessons</div>
                </div>
              </div>

              <div class="owl-slide">
                <div class="course-selec-outer">
                  <div class="course-selec-hd"></div>
                  <div class="course-selec-txt">On Campus 9 Weekly Lessons</div>
                </div>
              </div>
            </OwlCarousel>
          </div>
        </section>
      </div>
    </>
  );
}

export default CourseSelection;
