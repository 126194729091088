

import React, { useState, useEffect } from "react";
import CountUp from "react-countup";
import { apiRequest } from "../../../services";

function Counter() {
  const [counterData, setCounterData] = useState([]);
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await apiRequest("/api/counter/get-list", "GET");
      console.log("res", response);
      setCounterData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <section className="counter-sec">
      <div className="counter-inner container">
        <div className="prg-counter">
          <div className="prg-container row">
            {counterData.map((counter, index) => (
              <div className="col-md-3" key={index}>
                <div className="counter-box">
                  <CountUp
                    start={counter.start}
                    end={counter.end}
                    decimals={counter.decimals}
                    delay={counter.delay}
                    enableScrollSpy={counter.enableScrollSpy}
                    scrollSpyOnce={counter.scrollSpyOnce}
                  >
                    {({ countUpRef }) => (
                      <div>
                        <h3
                          ref={countUpRef}
                          className={counter.className}
                          data-count={counter.dataCount}
                        >
                          {counter.start}
                        </h3>
                      </div>
                    )}
                  </CountUp>
                  <h4 className="prg-count-title">{counter.title}</h4>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Counter;
