import React from "react";
// import video from "../../../assets/CS_Education_3840x2160_prob-3.mp4";

function OurVideoSection() {
  return (
    <>
      <div class="set-video container" id="ourVideo">
        <iframe
          width="100%"
          height="570"
          // src={video}
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
      </div>

      <section class="checkout-vd-sec">
        <div class="checkout-vd-inner container">
          <div class="checkout-vd-con">
            <div class="vd-title">Check Out Our Video</div>
          </div>
        </div>
      </section>
    </>
  );
}

export default OurVideoSection;
