import React from "react";

import OwlCarousel from "react-owl-carousel";

const options = {
  autoplay: true,
  loop: true,
  margin: 10,
  responsiveClass: true,
  dots: true,
  responsive: {
    0: {
      items: 1,
      nav: true,
      dotsEach: 2,
    },
    320: {
      items: 1,
      nav: true,
      dotsEach: 3,
    },
    375: {
      items: 1,
      nav: true,
      dotsEach: 2,
    },
    690: {
      items: 1,
      nav: true,
      dotsEach: 1,
    },
    730: {
      items: 1,
      nav: true,
      dotsEach: 1,
    },
    1000: {
      items: 1,
      nav: true,
      loop: true,
      dotsEach: 1,
    },
    1200: {
      items: 1,
      nav: true,
      loop: true,
      dotsEach: 1,
    },
  },
};

function HistoryCarauselTimeline() {
  return (
    <section class="hist-owl-carausel-sec">
      <div class="hist-owl-carausel-inner container">
        <OwlCarousel class="owl-carousel" options {...options}>
          <div class="hist-owl-slide">
            <div class="cs-slide-timeline">
              <div class="slide-hd">2016 CONNECTED SCHOOL IS LAUNCHED</div>
              <div class="slide-para">
                Connected School provides various educational classes and tests
                which students can access whenever and wherever they want.
                Students can purchase online tests and lessons, and can access
                each item as many times as they wish for a certain period of
                time, allowing them to thoroughly understand and comprehend its
                contents.
              </div>
            </div>
          </div>

          <div class="hist-owl-slide">
            <div class="cs-slide-timeline">
              <div class="slide-hd">2016 CONNECTED SCHOOL IS LAUNCHED</div>
              <div class="slide-para">
                Connected School provides various educational classes and tests
                which students can access whenever and wherever they want.
                Students can purchase online tests and lessons, and can access
                each item as many times as they wish for a certain period of
                time, allowing them to thoroughly understand and comprehend its
                contents.
              </div>
            </div>
          </div>

          <div class="hist-owl-slide">
            <div class="cs-slide-timeline">
              <div class="slide-hd">2016 CONNECTED SCHOOL IS LAUNCHED</div>
              <div class="slide-para">
                Connected School provides various educational classes and tests
                which students can access whenever and wherever they want.
                Students can purchase online tests and lessons, and can access
                each item as many times as they wish for a certain period of
                time, allowing them to thoroughly understand and comprehend its
                contents.
              </div>
            </div>
          </div>

          <div class="hist-owl-slide">
            <div class="cs-slide-timeline">
              <div class="slide-hd">2016 CONNECTED SCHOOL IS LAUNCHED</div>
              <div class="slide-para">
                Connected School provides various educational classes and tests
                which students can access whenever and wherever they want.
                Students can purchase online tests and lessons, and can access
                each item as many times as they wish for a certain period of
                time, allowing them to thoroughly understand and comprehend its
                contents.
              </div>
            </div>
          </div>

          <div class="hist-owl-slide">
            <div class="cs-slide-timeline">
              <div class="slide-hd">2016 CONNECTED SCHOOL IS LAUNCHED</div>
              <div class="slide-para">
                Connected School provides various educational classes and tests
                which students can access whenever and wherever they want.
                Students can purchase online tests and lessons, and can access
                each item as many times as they wish for a certain period of
                time, allowing them to thoroughly understand and comprehend its
                contents.
              </div>
            </div>
          </div>

          <div class="hist-owl-slide">
            <div class="cs-slide-timeline">
              <div class="slide-hd">2016 CONNECTED SCHOOL IS LAUNCHED</div>
              <div class="slide-para">
                Connected School provides various educational classes and tests
                which students can access whenever and wherever they want.
                Students can purchase online tests and lessons, and can access
                each item as many times as they wish for a certain period of
                time, allowing them to thoroughly understand and comprehend its
                contents.
              </div>
            </div>
          </div>

          <div class="hist-owl-slide">
            <div class="cs-slide-timeline">
              <div class="slide-hd">2016 CONNECTED SCHOOL IS LAUNCHED</div>
              <div class="slide-para">
                Connected School provides various educational classes and tests
                which students can access whenever and wherever they want.
                Students can purchase online tests and lessons, and can access
                each item as many times as they wish for a certain period of
                time, allowing them to thoroughly understand and comprehend its
                contents.
              </div>
            </div>
          </div>

          <div class="hist-owl-slide">
            <div class="cs-slide-timeline">
              <div class="slide-hd">2016 CONNECTED SCHOOL IS LAUNCHED</div>
              <div class="slide-para">
                Connected School provides various educational classes and tests
                which students can access whenever and wherever they want.
                Students can purchase online tests and lessons, and can access
                each item as many times as they wish for a certain period of
                time, allowing them to thoroughly understand and comprehend its
                contents.
              </div>
            </div>
          </div>

          <div class="hist-owl-slide">
            <div class="cs-slide-timeline">
              <div class="slide-hd">2016 CONNECTED SCHOOL IS LAUNCHED</div>
              <div class="slide-para">
                Connected School provides various educational classes and tests
                which students can access whenever and wherever they want.
                Students can purchase online tests and lessons, and can access
                each item as many times as they wish for a certain period of
                time, allowing them to thoroughly understand and comprehend its
                contents.
              </div>
            </div>
          </div>

          <div class="hist-owl-slide">
            <div class="cs-slide-timeline">
              <div class="slide-hd">2016 CONNECTED SCHOOL IS LAUNCHED</div>
              <div class="slide-para">
                Connected School provides various educational classes and tests
                which students can access whenever and wherever they want.
                Students can purchase online tests and lessons, and can access
                each item as many times as they wish for a certain period of
                time, allowing them to thoroughly understand and comprehend its
                contents.
              </div>
            </div>
          </div>

          <div class="hist-owl-slide">
            <div class="cs-slide-timeline">
              <div class="slide-hd">2016 CONNECTED SCHOOL IS LAUNCHED</div>
              <div class="slide-para">
                Connected School provides various educational classes and tests
                which students can access whenever and wherever they want.
                Students can purchase online tests and lessons, and can access
                each item as many times as they wish for a certain period of
                time, allowing them to thoroughly understand and comprehend its
                contents.
              </div>
            </div>
          </div>

          <div class="hist-owl-slide">
            <div class="cs-slide-timeline">
              <div class="slide-hd">2016 CONNECTED SCHOOL IS LAUNCHED</div>
              <div class="slide-para">
                Connected School provides various educational classes and tests
                which students can access whenever and wherever they want.
                Students can purchase online tests and lessons, and can access
                each item as many times as they wish for a certain period of
                time, allowing them to thoroughly understand and comprehend its
                contents.
              </div>
            </div>
          </div>
          <div class="hist-owl-slide">
            <div class="cs-slide-timeline">
              <div class="slide-hd">2016 CONNECTED SCHOOL IS LAUNCHED</div>
              <div class="slide-para">
                Connected School provides various educational classes and tests
                which students can access whenever and wherever they want.
                Students can purchase online tests and lessons, and can access
                each item as many times as they wish for a certain period of
                time, allowing them to thoroughly understand and comprehend its
                contents.
              </div>
            </div>
          </div>
          <div class="hist-owl-slide">
            <div class="cs-slide-timeline">
              <div class="slide-hd">2016 CONNECTED SCHOOL IS LAUNCHED</div>
              <div class="slide-para">
                Connected School provides various educational classes and tests
                which students can access whenever and wherever they want.
                Students can purchase online tests and lessons, and can access
                each item as many times as they wish for a certain period of
                time, allowing them to thoroughly understand and comprehend its
                contents.
              </div>
            </div>
          </div>

          <div class="hist-owl-slide">
            <div class="cs-slide-timeline">
              <div class="slide-hd">2016 CONNECTED SCHOOL IS LAUNCHED</div>
              <div class="slide-para">
                Connected School provides various educational classes and tests
                which students can access whenever and wherever they want.
                Students can purchase online tests and lessons, and can access
                each item as many times as they wish for a certain period of
                time, allowing them to thoroughly understand and comprehend its
                contents.
              </div>
            </div>
          </div>

          <div class="hist-owl-slide">
            <div class="cs-slide-timeline">
              <div class="slide-hd">2016 CONNECTED SCHOOL IS LAUNCHED</div>
              <div class="slide-para">
                Connected School provides various educational classes and tests
                which students can access whenever and wherever they want.
                Students can purchase online tests and lessons, and can access
                each item as many times as they wish for a certain period of
                time, allowing them to thoroughly understand and comprehend its
                contents.
              </div>
            </div>
          </div>
        </OwlCarousel>

        <div class="rocket-iconhist">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100"
            height="221"
            viewBox="0 0 100 221"
            fill="none"
          >
            <g opacity="0.5" clip-path="url(#clip0_275_407)">
              <path
                d="M75.194 90.4487C75.2223 90.4678 75.2479 90.4903 75.2755 90.5099C75.3518 90.4837 75.4344 90.5471 75.4367 90.6305C76.4997 91.4381 77.2999 92.6275 78.0122 93.7381C78.8175 94.9936 79.4996 96.3228 80.0559 97.7066C81.1703 100.479 81.8048 103.437 81.9436 106.421C82.2483 112.971 80.3936 119.455 77.5574 125.305C76.69 127.095 75.7376 128.841 74.6921 130.532C74.1524 131.406 73.6036 132.276 73.0145 133.118C72.7206 133.537 72.4335 133.968 72.1193 134.373C71.8025 134.782 71.463 135.162 71.1742 135.593C71.0746 135.742 70.8586 135.676 70.8188 135.517C69.4524 130.034 67.0489 124.906 63.2807 120.656C62.2222 119.462 61.0737 118.353 59.844 117.336C59.4645 117.023 59.8998 116.385 60.2814 116.702C60.342 116.752 60.4004 116.805 60.4606 116.856C60.8623 116.125 61.3967 115.443 61.8474 114.751C62.401 113.901 62.9469 113.046 63.4853 112.186C64.5344 110.511 65.5542 108.818 66.5449 107.107C68.5614 103.624 70.4556 100.07 72.2197 96.4529C72.7215 95.4237 73.2213 94.3929 73.7126 93.3583C73.9583 92.8409 74.1972 92.3202 74.4418 91.8023C74.638 91.3867 74.8286 90.9479 75.1464 90.6165C75.1292 90.5884 75.1101 90.5623 75.0932 90.5336C75.0572 90.473 75.1415 90.413 75.194 90.4487Z"
                fill="#D2A490"
              />
              <path
                d="M46.5367 76.2407C46.5048 76.249 46.4726 76.254 46.4405 76.2617C46.4316 76.7209 46.257 77.1663 46.1025 77.5993C45.9103 78.1387 45.7119 78.6762 45.5205 79.2162C45.138 80.2957 44.7644 81.3786 44.3934 82.462C43.0891 86.2694 41.9222 90.1239 40.8917 94.0142C40.3857 95.925 39.9128 97.8445 39.4731 99.7716C39.2476 100.76 39.0306 101.751 38.8228 102.744C38.6534 103.552 38.5504 104.413 38.319 105.214C38.3976 105.221 38.4761 105.225 38.5546 105.232C39.0489 105.277 38.907 106.036 38.4167 105.99C36.8281 105.841 35.2312 105.817 33.6388 105.915C27.9698 106.265 22.6477 108.2 17.8093 111.119C17.6686 111.204 17.4692 111.098 17.5037 110.922C17.6038 110.413 17.6315 109.904 17.6957 109.391C17.7593 108.882 17.8597 108.375 17.9479 107.869C18.1247 106.858 18.3502 105.854 18.5846 104.854C19.0384 102.918 19.5962 101.009 20.2451 99.1292C22.3667 92.983 25.6301 87.0813 30.4698 82.6571C32.6744 80.642 35.2118 78.9945 37.9586 77.8175C39.3294 77.23 40.751 76.7701 42.2076 76.4492C43.4962 76.1655 44.9025 75.8878 46.2256 76.0665C46.2862 76.0088 46.389 76.0221 46.4248 76.0948C46.4583 76.1006 46.4922 76.1027 46.5257 76.1091C46.588 76.1209 46.6053 76.2231 46.5367 76.2407Z"
                fill="#D2A490"
              />
              <path
                d="M65.9144 43.3913C70.5205 38.7943 75.621 34.6177 81.1999 31.2536C81.8935 30.8353 82.5935 30.4341 83.3095 30.0551C83.4621 29.9743 83.6185 30.0193 83.7311 30.119C83.933 29.9658 84.2671 30.0161 84.3573 30.3488C85.1713 33.3533 85.5729 36.4778 85.8399 39.5747C86.1109 42.7151 86.2477 45.8679 86.2475 49.0201C86.2469 55.2866 85.706 61.5506 84.6002 67.719C83.4638 74.059 81.7382 80.3228 79.5326 86.3728C77.156 92.8921 74.111 99.1643 70.5119 105.096C66.9099 111.032 62.7352 116.612 58.0646 121.749C57.4848 122.387 56.884 123.005 56.2796 123.619C56.0228 123.881 55.558 123.812 55.3132 123.57C55.117 123.653 54.9103 123.602 54.6782 123.551C54.2823 123.464 53.887 123.337 53.5009 123.214C52.7979 122.989 52.1023 122.735 51.4072 122.487C49.9919 121.981 48.5856 121.45 47.1893 120.894C44.3737 119.773 41.6002 118.546 38.8688 117.233C38.1118 116.869 37.3581 116.498 36.6085 116.118C36.2429 115.933 35.8778 115.747 35.5137 115.559C35.1135 115.352 34.6668 115.184 34.377 114.827C34.3129 114.748 34.2801 114.656 34.2699 114.564C34.1618 114.483 34.0934 114.351 34.1169 114.171C34.9738 107.583 36.0605 101.037 37.7058 94.595C39.3416 88.1891 41.4484 81.9128 44.0697 75.8417C46.6857 69.7821 49.7917 63.927 53.4795 58.45C57.1168 53.0495 61.3058 47.9904 65.9144 43.3913Z"
                fill="#A76E5E"
              />
              <path
                d="M14.9793 149.988C15.6198 149.49 16.1702 148.898 16.7398 148.321C17.3076 147.745 17.8951 147.188 18.4711 146.621C19.623 145.486 20.7711 144.347 21.9058 143.195C24.1778 140.888 26.3622 138.503 28.5616 136.128C29.79 134.802 30.9688 133.377 32.4296 132.301C32.6201 132.161 32.8448 132.373 32.7301 132.574C32.7208 132.591 32.71 132.607 32.7007 132.623C34.4434 134.097 36.3643 135.362 38.4226 136.352C39.4488 136.845 40.5055 137.274 41.5852 137.635C42.1358 137.82 42.6906 137.992 43.251 138.144C43.6173 138.243 44.3658 138.277 44.5318 138.675C44.5339 138.68 44.5319 138.684 44.5331 138.689C44.5874 138.691 44.6416 138.722 44.655 138.786C45.2121 141.489 45.0127 144.354 44.6068 147.067C44.1948 149.822 43.4381 152.504 42.4105 155.092C42.3339 155.285 42.0638 155.359 41.9247 155.181C40.6162 153.511 39.3076 151.841 38.0022 150.168C37.7115 151.605 37.404 153.031 36.9809 154.441C36.4948 156.062 35.8988 157.664 35.2725 159.235C34.0193 162.379 32.4509 165.337 30.5304 168.125C29.4299 169.722 28.3159 171.307 27.1547 172.861C27.1004 172.934 26.9507 172.901 26.9711 172.796C27.1159 172.051 27.1892 171.28 27.3142 170.529C27.4401 169.772 27.5578 169.014 27.6619 168.254C27.8704 166.731 28.0818 165.208 28.2325 163.678C28.5057 160.9 28.6382 158.101 28.6593 155.308C27.8484 156.292 27.0853 157.316 26.2632 158.292C25.2657 159.475 24.246 160.64 23.206 161.786C22.1802 162.916 21.1441 164.042 20.0638 165.121C18.9689 166.214 17.8268 167.258 16.7245 168.343C16.4952 168.568 16.1743 168.275 16.2452 168.012C17.6428 162.846 19.1093 157.698 20.5621 152.547C20.8766 151.432 21.1912 150.317 21.5055 149.202C19.5298 150.179 17.3832 151 15.16 150.549C14.9105 150.499 14.7373 150.177 14.9793 149.988Z"
                fill="black"
              />
              <path
                d="M50.306 88.4924C52.2031 87.5774 54.4431 87.1732 56.5275 87.5579C58.8155 87.9803 60.9416 89.1347 62.4387 90.9376C63.7083 92.4668 64.3876 94.3968 64.5167 96.378C64.518 96.3859 64.5199 96.3936 64.5212 96.4015C64.8686 98.5182 64.3296 100.681 63.2971 102.532C61.3097 106.093 56.9733 108.009 52.9851 107.546C48.7496 107.055 45.1358 103.851 44.2687 99.6461C43.3229 95.0602 46.2303 90.4583 50.306 88.4924Z"
                fill="#FFC1A7"
              />
              <path
                d="M89.353 10.586C89.449 10.4642 89.5583 10.3474 89.6668 10.237C89.6934 10.21 89.7202 10.1831 89.7505 10.1603C89.9096 10.0407 90.0889 9.92225 90.2778 9.85156C90.3759 9.81461 90.4734 9.7835 90.5712 9.75655C90.7762 9.67536 90.9883 9.6344 91.211 9.64964C91.2339 9.64862 91.2569 9.64732 91.28 9.6472C91.3881 9.6454 91.4945 9.65511 91.6008 9.67544C91.795 9.71228 92.0173 9.74311 92.2048 9.82634C92.4107 9.9178 92.6283 10.0161 92.8034 10.1624C93.1178 10.4252 93.4004 10.7253 93.5444 11.119C93.6854 11.5043 93.7708 11.8846 93.7306 12.2997C93.689 12.7292 93.556 13.1001 93.3281 13.465C93.3051 13.5017 93.2793 13.5362 93.2519 13.5698C93.1498 13.6944 93.0378 13.8219 92.9196 13.9318C92.7696 14.0719 92.5841 14.1807 92.4036 14.2739C92.2289 14.3642 92.0258 14.4194 91.8374 14.4663C91.6466 14.5139 91.4289 14.514 91.2405 14.5172C90.965 14.5217 90.6915 14.4609 90.4359 14.3596C90.3629 14.5563 90.2878 14.7514 90.2182 14.9452C89.9518 15.6879 89.6947 16.4352 89.413 17.1723C88.8586 18.623 88.3019 20.073 87.7465 21.5235C86.626 24.4492 85.4657 27.3602 84.3382 30.2834C84.1829 30.686 83.5505 30.4673 83.7041 30.0628C84.8173 27.1353 85.8985 24.1952 87.0186 21.2704C87.5742 19.8199 88.1287 18.3692 88.6851 16.9192C88.9684 16.1814 89.2763 15.453 89.575 14.7215C89.6659 14.4985 89.7524 14.2687 89.8437 14.0401C89.6733 13.9229 89.517 13.7875 89.3875 13.614C89.1463 13.2904 88.9701 12.9389 88.9015 12.537C88.8309 12.1253 88.8718 11.7662 88.9714 11.3662C88.9922 11.2824 89.0204 11.2023 89.0571 11.1239C89.1411 10.9422 89.2272 10.7454 89.353 10.586Z"
                fill="black"
              />
              <path
                d="M80.4929 10.9442C81.4283 10.8277 82.285 11.3467 83.0635 11.7922C84.0166 12.3375 84.9463 12.9171 85.8486 13.5433C86.9605 14.3146 88.0254 15.1414 89.069 15.9961C89.0583 16.0134 89.0449 16.0288 89.0378 16.0476C89.0112 16.1189 88.981 16.1889 88.9524 16.2596C88.9284 16.3089 88.9026 16.3572 88.8835 16.4088C88.8731 16.4365 88.8662 16.4655 88.8572 16.4937C88.8229 16.5719 88.793 16.6515 88.7674 16.7324C88.6745 16.645 88.5814 16.5575 88.4919 16.466C88.4701 16.4439 88.4558 16.419 88.4464 16.3931C86.5018 14.8459 84.5127 13.34 82.3207 12.1827C81.69 11.8496 80.8569 11.4327 80.1594 11.8294C79.539 12.1822 79.7212 12.8747 80.0091 13.3893C80.3638 14.0227 80.8946 14.5726 81.3556 15.1283C81.9111 15.7974 82.4744 16.4602 83.0487 17.1131C85.2839 19.6541 87.6945 22.0503 90.4262 24.0585C91.7683 25.0454 93.1943 25.9902 94.7105 26.689C95.3901 27.0022 96.5314 27.2156 96.8199 26.2614C97.0192 25.6018 96.4938 24.8482 96.1623 24.3192C95.6508 23.5027 95.0949 22.721 94.4845 21.9759C93.9105 21.2755 93.245 20.6752 92.6121 20.0296C91.6162 19.0139 90.4402 18.2023 89.3677 17.2784C89.3788 17.2603 89.3933 17.243 89.3998 17.2244C89.4047 17.2102 89.4027 17.1962 89.4054 17.1822C89.4099 17.165 89.4176 17.1483 89.4238 17.1314C89.4408 17.0927 89.4564 17.0529 89.4707 17.0131C89.4856 16.9818 89.5008 16.9505 89.5136 16.9193C89.568 16.7873 89.6025 16.6469 89.6466 16.5113C89.6504 16.4998 89.6484 16.4889 89.6507 16.4775C89.8374 16.6332 90.0249 16.7876 90.2107 16.945C91.2052 17.681 92.0791 18.6177 92.9046 19.4996C93.5409 20.1793 94.0627 20.9284 94.6209 21.6715C95.1515 22.3782 95.6554 23.1092 96.1272 23.8567C96.7184 24.7934 97.8498 26.5767 96.3063 27.2747C95.6265 27.582 94.8543 27.3828 94.2146 27.0744C93.376 26.6699 92.5625 26.2086 91.7718 25.7177C88.5227 23.7003 85.6987 21.0618 83.1258 18.2501C82.4692 17.5327 81.8277 16.8016 81.1969 16.0612C80.6316 15.3975 80.0171 14.7435 79.5328 14.0165C79.1811 13.4882 78.8853 12.8579 79.018 12.2088C79.1638 11.4973 79.7924 11.0316 80.4929 10.9442Z"
                fill="#B56949"
              />
              <path
                d="M33.4085 170.448C32.9287 171.845 32.3281 173.237 31.9766 174.672C31.6255 176.106 31.0262 177.497 30.5043 178.879C29.4552 181.655 28.4239 184.444 27.2353 187.165C24.8649 192.591 22.2684 197.918 19.4608 203.131C18.6738 204.592 17.8903 206.079 17.0101 207.487C16.5642 208.2 16.0665 208.887 15.6129 209.598C15.1584 210.31 14.6996 211.019 14.2369 211.726C13.922 212.207 13.2328 211.711 13.5106 211.225C14.2451 209.939 15.0391 208.658 15.6601 207.313C16.2712 205.99 17.0885 204.745 17.7978 203.471C19.2309 200.897 20.6508 198.32 21.9978 195.699C23.3465 193.076 24.6377 190.423 25.8747 187.746C27.1126 185.067 28.3876 182.399 29.5377 179.681C29.8579 178.925 30.1733 178.167 30.4845 177.408C30.8041 176.628 31.0816 175.818 31.4378 175.054C31.7889 174.302 32.1358 173.574 32.4247 172.793C32.718 172 33.0065 171.204 33.2906 170.408C33.318 170.332 33.4343 170.373 33.4085 170.448Z"
                fill="#D2D2D2"
              />
              <path
                d="M51.9093 141.254C52.4699 144.18 52.952 147.108 53.1717 150.081C53.3919 153.059 53.4086 156.057 53.2453 159.038C52.9202 164.973 51.8703 170.882 50.1319 176.566C49.6391 178.177 49.0054 179.725 48.3852 181.29C47.7655 182.853 47.1398 184.433 46.3016 185.893C46.0083 186.405 45.2278 185.967 45.4007 185.437C45.8523 184.053 46.4223 182.711 46.9103 181.338C47.4075 179.939 47.779 178.508 48.2195 177.093C49.0835 174.317 49.8197 171.49 50.3919 168.64C51.5425 162.909 52.0868 157.099 52.1448 151.259C52.1778 147.94 52.0864 144.588 51.7013 141.292C51.6859 141.158 51.8835 141.119 51.9093 141.254Z"
                fill="#D2D2D2"
              />
              <path
                d="M42.1976 163.616C42.6236 161.888 43.0889 160.172 43.3816 158.415C43.3984 158.314 43.5574 158.324 43.5428 158.427C43.3242 159.986 43.2028 161.555 42.9744 163.112C42.7503 164.638 42.4636 166.146 42.1353 167.653C41.4673 170.719 40.6525 173.743 39.7135 176.737C37.8364 182.722 35.4823 188.541 32.6783 194.152C31.1016 197.306 29.4111 200.444 27.3601 203.318C27.1732 203.58 26.8232 203.312 26.9612 203.043C28.3849 200.264 29.9083 197.538 31.3089 194.746C32.6914 191.99 33.9683 189.181 35.1374 186.327C37.4884 180.588 39.3979 174.703 40.9541 168.7C41.3916 167.011 41.78 165.31 42.1976 163.616Z"
                fill="#D2D2D2"
              />
              <path
                d="M44.4429 195.135L46.1224 193.527L48.3989 193.999L47.3888 191.905L48.5412 189.885L46.2371 190.199L44.6727 188.479L44.2591 190.767L42.1401 191.724L44.1881 192.824L44.4429 195.135Z"
                fill="#A8A8A8"
              />
              <path
                d="M25.2457 212.35L27.304 210.38L30.0942 210.958L28.8561 208.391L30.2684 205.917L27.4449 206.301L25.5275 204.193L25.0207 206.997L22.4233 208.169L24.9334 209.518L25.2457 212.35Z"
                fill="#A8A8A8"
              />
              <path
                d="M11.0301 184.77L13.0884 182.799L15.8789 183.377L14.6405 180.811L16.0528 178.336L13.2293 178.72L11.3119 176.612L10.8051 179.417L8.20771 180.589L10.7181 181.937L11.0301 184.77Z"
                fill="#A8A8A8"
              />
              <path
                d="M60.1869 58.8219C64.5638 55.1692 71.0516 55.1965 75.3764 59.8115C75.6744 60.1297 79.128 63.6103 78.0394 69.8476C77.2086 74.6075 72.7524 78.3782 67.9352 78.7402C63.1401 79.1007 58.4335 75.991 56.6904 71.5424C54.9485 67.0976 56.5788 61.833 60.1869 58.8219Z"
                fill="#E6DAC2"
              />
              <path
                d="M61.8363 60.8667C65.1785 58.0775 70.1324 58.0982 73.4345 61.6224C73.6621 61.8653 76.2991 64.5228 75.4678 69.2856C74.8334 72.9202 71.4307 75.7993 67.7526 76.076C64.0913 76.3511 60.4975 73.9768 59.1663 70.5798C57.8361 67.186 59.0812 63.1659 61.8363 60.8667Z"
                fill="black"
              />
              <path
                d="M27.3807 125.382C28.5052 123.295 29.6365 121.212 30.7661 119.128C31.3063 118.131 31.8468 117.135 32.387 116.138C32.8348 115.312 33.0886 114.562 33.8967 114.077C33.9013 114.074 33.9069 114.074 33.9123 114.072C34.0326 113.828 34.362 113.661 34.6505 113.872C37.9129 116.247 41.3924 118.295 45.0797 119.939C46.8076 120.709 48.5748 121.396 50.3731 121.985C52.1041 122.551 54.2203 122.799 55.8328 123.674C56.0078 123.602 56.2403 123.726 56.2054 123.961C55.9121 125.922 55.1113 127.836 54.5099 129.72C53.8475 131.794 53.1748 133.864 52.5036 135.935C52.4627 136.061 52.39 136.139 52.3037 136.182C52.2668 136.302 52.1732 136.396 52.0185 136.391C43.1263 136.069 34.2478 131.838 27.6537 126.019C27.6299 125.998 27.6111 125.976 27.5925 125.954C27.3842 125.863 27.2418 125.639 27.3807 125.382Z"
                fill="#E6DAC2"
              />
            </g>
            <defs>
              <clipPath id="clip0_275_407">
                <rect
                  width="63.9302"
                  height="212.6"
                  fill="white"
                  transform="matrix(-0.985108 -0.171934 -0.171934 0.985108 99.7324 11.0941)"
                />
              </clipPath>
            </defs>
          </svg>
        </div>
      </div>
    </section>
  );
}

export default HistoryCarauselTimeline;
