import React, { useState, useEffect } from "react";
import { apiRequest } from '../../../services';

function GetInTouch() {
  const [branch, setBranch] = useState([]);
  const [grade, setGrade] = useState([]);
  const [inputs, setInputs] = useState({
    name: '',
    email: '',
    mobile: '',
    branch: '',
    grade: '',
    message: ''
  });

  const handleChange = (e) => {
    let { name, value } = e.target;
    setInputs((prev) => ({ ...prev, [name]: value }));

  };


  const handleSubmit = async () => {
    console.log(inputs.email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/))
    if (inputs.email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/) === null) {
      window.alert("please enter correct email")
    }
    else{
    try {
      const response = await apiRequest('api/users/contact-us', 'POST', inputs); 
      console.log('Response:', response);
      setInputs({
        name: '',
        email: '',
        mobile: '',
        branch: '',
        grade: '',
        message: ''
      });
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  }
  };




  useEffect(() => {
    fetchbranch();
  }, []);

  //  get branch 
  const fetchbranch = async () => {
    try {
      const response = await apiRequest('http://65.1.219.190:4040/api/grades-all', 'GET');
      console.log('data', response);
      setGrade(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };



  useEffect(() => {
    fetchgrade();
  }, []);

  //  get grade
  const fetchgrade = async () => {
    try {
      const response = await apiRequest('http://65.1.219.190:4040/api/branches-all', 'GET');
      console.log('data', response);
      setBranch(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };



  return (
    <>
      <section class="get-in-touch-sec">
        <div class="get-in-touch-inner container">
          <div class="get-in-toch-con">
            <div class="xs-heading" data-aos="fade-left">
              Need Help?
            </div>
            <div class="lg-heading" data-aos="fade-right">
              Get In Touch With us
            </div>
            <div class="form-inner">
              <form>
                <div class="row">
                  <div class="col-6">
                    <input
                      name="name"
                      type="text"
                      class="form-control"
                      placeholder="Enter your Full Name"
                      value={inputs.name}
                      onChange={handleChange}
                    />
                  </div>
                  <div class="col-6">
                    <input
                      name="email"
                      type="email"
                      class="form-control"
                      placeholder="Enter your Email"
                      value={inputs.email}
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div class="row">
                  <div class="col-12 col-sm-4">
                    <input
                      name="mobile"
                      type="tel"
                      class="form-control"
                      placeholder="Phone Number"
                      pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                      value={inputs.mobile}
                      onChange={handleChange}
                    />
                  </div>
                  <div class="col-12 col-sm-4">
                    <select
                      name="branch"
                      class="form-select set-select"
                      aria-label="Default select example"
                      value={inputs.branch}
                      onChange={handleChange}
                    >
                      <option value=''>Cs Branch (if know)</option>
                      {branch?.map((e)=>{
                        return(
                          <option value={e.id}>{e.branch_name}</option>
                        )
                      })}
                      
                    </select>
                  </div>
                  <div class="col-12 col-sm-4">
                    <select
                      name="grade"
                      class="form-select set-select"
                      aria-label="Default select example"
                      value={inputs.grade}
                      onChange={handleChange}
                    >
                      <option value=''>Grade</option>
                      {grade?.map((e)=>{
                        return(
                          <option value={e.id}>{e.name}</option>
                        )
                      })}
                    </select>
                  </div>
                </div>

                <div class="row">
                  <div class="col">
                    <textarea
                      name="message"
                      rows="6"
                      cols="50"
                      class="form-control"
                      placeholder="Add Message"
                      value={inputs.message}
                      onChange={handleChange}
                    ></textarea>
                  </div>
                </div>

                <div class="row">
                  <div class="sap-black-btn" onClick={handleSubmit}>
                    <a href="#">Send Request</a>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default GetInTouch;
