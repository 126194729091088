import React from "react";
import images from "../../../assets";
function EducationTimeline() {
  return (
    <section class="timeline-sec">
      <div class="timeline-inner container">
        <div class="timeline-con">
          <div class="planet-icon">
            <img {...images["frame.png"]} />
          </div>
          <div class="lg-heading" data-aos="fade-right">
            <span class="red-text">CS Education</span> Timeline
          </div>
          <div class="text-para">
            <p>
              In 2002 Testimony and Phoebe Kim commenced a coaching college in
              Castle Hill. They specialised in the preparation of students for
              OC and Selective School exams and quickly established a reputation
              for success in this field. The college developed and other courses
              were added so that all ages from K – HSC were covered.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default EducationTimeline;
