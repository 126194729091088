import React, { useState, useEffect } from "react";
import { apiRequest } from '../../../services';
function Signup({ setInputs }) {
  const [grades, setGrades] = useState([]);
  const [school, setSchool] = useState([]);
  const [student, setStudent] = useState({
    first_name: '',
    last_name: '',
    gender: '',
    date_of_birth: '',
    email: '',
    school: '',
    grade: '',
    p_first_name: '',
    p_last_name: '',
    p_mobile_phone: '',
    p_email: '',
    password: '',
    confirm_password: '',
  });



  const handleStudentChange = (e) => {
    let { name, value } = e.target;

    setStudent((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault()
    const api = 'http://13.200.114.252:4040/api/student/student-signup'
    try {
      const response = await apiRequest(api, 'POST', student); // Replace 'your-endpoint' with the actual API endpoint
      console.log('Response:', response);

      //  course: '',
      //   branch: '',
      //   first_name: '',
      //   last_name: '',
      //   gender: '',
      //   date_of_birth: '',
      //   school: '',
      //   grade: '',
      //   p_first_name: '',
      //   p_last_name: '',
      //   student_number: '',
      //   mother_mobilenumber: '',
      //   father_mobilenumber: '',
      //   address: '',
      //   city: '',
      //   post_code: '',
      //   state: '',
      //   country: '',
      //   p_email: '',
      //   email: '',
      //   password: '',
      //   c_passowrd: '',
      //   authorizedCode: '',
      //   captcha: ''
      setStudent({
        first_name: '',
        last_name: '',
        gender: '',
        date_of_birth: '',
        email: '',
        school: '', 
        grade: '',
        p_first_name: '',
        p_last_name: '',
        p_mobile_phone: '',
        p_email: '',
        password: '',
        confirm_password: '',
      });
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  useEffect(() => {
    // fetch list of grads.
    fetchgrades();
    fetchschool();
  }, []);


  //  get grades 
  const fetchgrades = async () => {
    try {
      const response = await apiRequest('http://61.246.113.222:4040/api/grades-all', 'GET');
      console.log('data', response);
      setGrades(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  //  get school 
  const fetchschool = async () => {
    try {
      const response = await apiRequest('http://13.200.114.252:4040/api/school-all', 'GET');
      console.log('data', response);
      setSchool(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  return (
    <>
      {" "}
      <div class="new-student-page">
        <section class="form-sec grey-back">
          <div class="form-inner container">
            {/* <form>
              <div class="xs-heading">Parent or Guardian Details</div>

              <div class="row">
                <div class="col-12 col-sm-6">
                  <div class="form-group">
                    <label>
                      First Name<sup>*</sup>
                    </label>
                    <input type="text" value={student.p_first_name} name="p_first_name"
                      onChange={handleStudentChange} class="form-control" placeholder="" />
                  </div>
                </div>

                <div class="col-12 col-sm-6">
                  <div class="form-group">
                    <label>
                      Last Name<sup>*</sup>
                    </label>
                    <input type="text" value={student.p_last_name} name="p_last_name"
                      onChange={handleStudentChange} class="form-control" placeholder="" />
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-12">
                  <div class="form-group">
                    <label>
                      Email<sup>*</sup>
                    </label>
                    <input type="email" value={student.p_email} name="p_email"
                      onChange={handleStudentChange} class="form-control" placeholder="" />
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-12">
                  <div class="form-group">
                    <label>
                      Mobile<sup>*</sup>
                    </label>
                    <input type="" value={student.p_mobile_phone} name="p_mobile_phone"
                      onChange={handleStudentChange} class="form-control" placeholder="" />
                  </div>
                </div>
              </div>


              <div class="row">
                <div class="col-12">
                  <div class="form-group">
                    <label>
                      Password<sup>*</sup>
                    </label>
                    <input type="" value={student.password} name="password"
                      onChange={handleStudentChange} class="form-control" placeholder="" />
                  </div>
                </div>
              </div>

              <div class="xs-heading">Student Details</div>

              <div class="row">
                <div class="col-12 col-sm-6">
                  <div class="form-group">
                    <label>
                      First Name<sup>*</sup>
                    </label>
                    <input type="text" value={student.first_name} name="first_name"
                      onChange={handleStudentChange} class="form-control" placeholder="" />
                  </div>
                </div>

                <div class="col-12 col-sm-6">
                  <div class="form-group">
                    <label>
                      Last Name<sup>*</sup>
                    </label>
                    <input type="text" value={student.last_name} name="last_name"
                      onChange={handleStudentChange} class="form-control" placeholder="" />
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-12 col-sm-6">
                  <div class="form-group">
                    <label>
                      DOB<sup>*</sup>
                    </label>
                    <input type="text" value={student.date_of_birth} name="date_of_birth"
                      onChange={handleStudentChange} class="form-control" placeholder="" />
                  </div>
                </div>

                <div class="col-12 col-sm-6">
                  <div class="form-group">
                    <label>
                      Gender<sup>*</sup>
                    </label>
                    <select
                      name="gender"
                      class="form-select set-select"
                      aria-label="Default select example"
                      value={student.gender}
                      onChange={handleStudentChange}
                    >
                      <option selected>Gender</option>
                      <option value="1">Male</option>
                      <option value="2">Female</option>
                    </select>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-12 col-sm-8">
                  <div class="form-group">
                    <label>
                      School<sup>*</sup>
                    </label>

                    <select
                      name="school"
                      class="form-select set-select"
                      aria-label="Default select example"
                      value={student.school}
                      onChange={handleStudentChange}
                    >
                      <option selected>School</option>
                      {school.map((data, key) => (
                        <option value={data.id} key={key}>{data.school_name}</option>
                      ))}
                    </select>

                  </div>
                </div>

                <div class="col-12 col-sm-4">
                  <div class="form-group">
                    <label>
                      Grade<sup>*</sup>
                    </label>

                    <select
                      name="grade"
                      class="form-select set-select"
                      aria-label="Default select example"
                      value={student.grade}
                      onChange={handleStudentChange}
                    >
                      <option selected>Grade</option>
                      {grades.map((data, key) => (
                        <option key={key.id} value={data.name}>{data.name}</option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-sm-12">
                  <div class="form-group">
                    <label>
                      Email<sup>*</sup>
                    </label>
                    <input type="email" value={student.email} name="email"
                      onChange={handleStudentChange} class="form-control" placeholder="" />
                  </div>
                </div>
              </div>
              <button onClick={handleSubmit}>submit</button>
            </form> */}
            <div class="action-sec2">
              <a target="_blank" href="http://61.246.113.222:3002/join-member">
              Click To Signup
              </a>
              </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default Signup;
