import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import images from "../../../assets";
import { apiRequest, capitalizeFirstLetter } from "../../../services";

// import useImageImport from "../../../assets/index";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
function Footer() {
  // const images = useImageImport();
  const [footerData, setFooterDataa] = useState([])

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = async () => {
    try {
      const response = await apiRequest('/api/footer-sections/get-list', 'GET');
      console.log("res", response);
      setFooterDataa(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <>
       {/* Not required for now<div class="footer-icons">
        <div class="footer-top-icon">
          <img {...images["pencil-girl"]} />
        </div>
        <div class="footer-top-icon-rt">
          <img {...images["pencil-boy"]} />
        </div>
      </div>
      <div class="footer-border">
        <img {...images["footer-border"]} />
  </div> */}
      <section class="footer-sec">
        <div class="footer-inner container">
          <div class="footer-grid-box">
            <div class="footer-col">
              <div class="footer-logo">
                <img {...images["footer-logo"]} />
              </div>
              <div class="footer-para">
              <strong>CS Education</strong> is a well-established college that has provided comprehensive coaching and tutoring services to students since 2002. Its aim is to improve the educational opportunities available to students in Australia and other regions in the Asia-Pacific. 
              </div>
              <div class="social-icons">
                <a href="">
                  <FontAwesomeIcon
                    icon="fa-brands fa-google"
                    style={{ color: "#fff" }}
                  />
                </a>
                <a href="">
                  <FontAwesomeIcon
                    icon="fa-brands fa-linkedin-in"
                    style={{ color: "#fff" }}
                  />
                </a>
                <a href="">
                  <FontAwesomeIcon
                    icon="fa-brands fa-facebook-f"
                    style={{ color: "#fff" }}
                  />
                </a>
                <a href="">
                  <FontAwesomeIcon
                    icon="fa-brands fa-twitter"
                    style={{ color: "#fff" }}
                  />
                </a>
              </div>
            </div>
            {footerData?.map((data, key) => (
              <FooterColumn key={key} title={data.title} links={data.list} />
            ))}
          </div>
        </div>
      </section>

      <section class="cpyrt-sec">
        <div class="cpyrt-inner container">
          <span class="cpyrt-txt">
            © Copyright 2007-2023 CS Education & CS Online School
          </span>

          <span class="cpyrt-txt">Our Branches : VIC | QLD | SA</span>
        </div>
      </section>
    </>
  );
}

export default Footer;

const FooterColumn = ({ title, links }) => (
  <div className="footer-col">
    {title && <div className="footer-col-title">{title}</div>}
    <div className="footer-list">
      <ul>
        {links.map((link, index) => (
          <li key={index}>
            <Link to={link.url}>
              {capitalizeFirstLetter(link.label)}
              {link.content &&
                link.content.map((item, idx) => (
                  <span key={idx}>
                    &nbsp;{item}
                    {link.content.length > 1 && link.content.length - 1 != idx
                      ? ","
                      : ""}
                    &nbsp;
                  </span>
                ))}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  </div>
);
