import React from "react";
import "./blogdetail.css";
import images from "../../assets";
import {
  BlogDetailSection,
  CommonBanner,
  Footer,
  Header,
  PreviousNextBtn,
  SearchSimilarSIdeBar,
} from "../../components";

const dataToSend = {
  bannerImg: images["courses-detail"],
  bannerHeading:
    "Expending CS Education Castle Campus to a new location right next door.",
  path: [],
};

function BlogDetail() {
  return (
    <div class="blog-detail-page">
      <Header />
      <CommonBanner {...dataToSend} />
      <div class="center-content container">
        <BlogDetailSection />
        <SearchSimilarSIdeBar />
      </div>
      <PreviousNextBtn />
      <Footer />
    </div>
  );
}

export default BlogDetail;
