import React from "react";
import images from "../../../assets";
function MessageSection() {
  return (
    <section class="message-sec">
      <div class="message-inner container">
        <div class="message-con-lf">
          <div class="lg-heading" data-aos="fade-right">
            A Message from <span class="red-text">our CEO </span>
          </div>
          <div class="text-para">
            <p>
              The board, management and staff are very pleased to introduce to
              you CS Education Group [CS Education, CS Edu U, CS Institute
              Australia, CS Franchising, CS Digital Publishing, Personal
              Advantage – PA+, CS Online School, Connected School, Writing
              School, OmniAll]. We aim to deliver quality education and learning
              programs to students from Kindy to Year 12 and adult individuals
              to pursue their education, training and career pathway. Since the
              foundation of CS Education in 1992, we have committed ourselves to
              providing students comprehensive tutoring and coaching programs.
              We also aim to deliver quality vocational and training programs to
              all unique individuals. CS Education team is enthusiastic to adopt
              innovative techniques for educating the student. The CS Online
              School has further developed into offering Blended Learning. We
              take great pride in our work, care and passion.
            </p>
          </div>

          <div class="designation">CO-FOUNDER AT CS ONLINE SCHOOL</div>
          <div class="name-hd">Testimony Kim</div>
        </div>

        <div class="message-con-rt">
          <div class="message-con-rt-img">
            <img {...images["his-message"]} />
          </div>
        </div>
      </div>
    </section>
  );
}

export default MessageSection;
