import React from "react";

function EnrollmentSummary() {

  
  return (
    <div class="enrollement-summary-page">
      <section class="enrollment-sec grey-back">
        <div class="enrollement-inner container">
          <div class="font-30 text-center pb-2">Enrollment Summary</div>

          <div class="enrol-header">
            <div class="enrol-left">
              <div class="term-course">Term Course</div>
            </div>

            <div class="enrol-right">
              <div class="enrol-campus">Campus</div>
              <div class="enrol-lesson">Lessons</div>
              <div class="enrol-price">Price</div>
            </div>
          </div>

          <div class="enrol-content">
            <div class="enrol-left">
              <div class="term-course">
                <div class="enrol-course-hd">Year 8 English</div>
                <div class="enrol-timings">Thursday, 6:10pm - 7:40pm</div>
                <div class="enrol-date">12 Oct - 7 Dec 2023</div>
                <div class="enrol-course">
                  Journalism and Discursive Writing
                </div>
                <div class="bg-grey">ER21</div>
              </div>
            </div>

            <div class="enrol-right">
              <div class="enrol-campus">Epping</div>
              <div class="enrol-lesson">9/9</div>
              <div class="enrol-price">$630</div>
            </div>
          </div>

          <div class="enrol-content">
            <div class="enrol-left">
              <div class="term-course">
                <div class="enrol-course-hd">Year 8 English</div>
                <div class="enrol-timings">Thursday, 6:10pm - 7:40pm</div>
                <div class="enrol-date">12 Oct - 7 Dec 2023</div>
                <div class="enrol-course">
                  Journalism and Discursive Writing
                </div>
                <div class="bg-grey">ER21</div>
              </div>
            </div>

            <div class="enrol-right">
              <div class="enrol-campus">Epping</div>
              <div class="enrol-lesson">9/9</div>
              <div class="enrol-price">$630</div>
            </div>
          </div>

          <div class="enrol-content">
            <div class="enrol-left">
              <div class="term-course">
                <div class="enrol-course-hd">Year 8 English</div>
                <div class="enrol-timings">Thursday, 6:10pm - 7:40pm</div>
                <div class="enrol-date">12 Oct - 7 Dec 2023</div>
                <div class="enrol-course">
                  Journalism and Discursive Writing
                </div>
                <div class="bg-grey">ER21</div>
              </div>
            </div>

            <div class="enrol-right">
              <div class="enrol-campus">Epping</div>
              <div class="enrol-lesson">9/9</div>
              <div class="enrol-price">$630</div>
            </div>
          </div>

          <div class="enrol-content">
            <div class="enrol-left">
              <div class="term-course">
                <div class="enrol-course-hd">Year 8 English</div>
                <div class="enrol-timings">Thursday, 6:10pm - 7:40pm</div>
                <div class="enrol-date">12 Oct - 7 Dec 2023</div>
                <div class="enrol-course">
                  Journalism and Discursive Writing
                </div>
                <div class="bg-grey">ER21</div>
              </div>
            </div>

            <div class="enrol-right">
              <div class="enrol-campus">Epping</div>
              <div class="enrol-lesson">9/9</div>
              <div class="enrol-price">$630</div>
            </div>
          </div>
        </div>
      </section>
    </div>
    
  );
}

export default EnrollmentSummary;
