import react from "react";
import { Link, useNavigate } from "react-router-dom";
import images from "../../../assets";
function Card({ cardData, cardType }) {
  const navigate = useNavigate();
  return (
    <div className="cag-grid">
      {cardData.map((course, key) => (
        <div
          key={course.id}
          onClick={() => {
            
            cardType.toLowerCase() == "course"
              ? navigate("/course-detail")
              : navigate("/blog-detail");
          }}
          className="cag-box"
        >
          <div className="cag-img">
            <img {...images[course.id]} />
          </div>

          <div className="cag-bot">
            <div className="cag-title">{course.title}</div>
            <div className="cag-combo-btn">
              {cardType.toLowerCase() == "course" ? (
                <>
                  <div className="sap-black-btn">
                    <Link to="/our-branches">Enroll Now</Link>
                  </div>
                  <div className="cag-rt-btn">
                    <Link to="/courses-details">View More</Link>
                  </div>
                </>
              ) : cardType.toLowerCase() == "blog" ? (
                <div class="cag-date">16 September 2022</div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default Card;
