import React, { useEffect } from "react";
import images from "../../../assets";
import OwlCarousel from "react-owl-carousel"; // Replace with the correct package name

const options = {
  autoplay: true,
  loop: true,
  margin: 10,
  responsiveClass: true,
  dots: true,
  responsive: {
    0: {
      items: 1,
      nav: true,
      dotsEach: 4,
    },
    600: {
      items: 2,
      nav: true,
      dotsEach: 3,
    },
    1000: {
      items: 3,
      nav: true,
      loop: true,
    },
    1200: {
      items: 4,
      nav: true,
      loop: true,
    },
  },
};
function OurPartners() {
  const carouselItems = [
    // You can map over your data here to dynamically generate the carousel items
    images["logo_franchise"],
    images["logo_group"],
    images["logo_testbank"],
    images["logo_institute"],
    images["logo_online"],
    images["logo_institute"],
    images["logo_online"],
  ];

  return (
    <section class="partner-carausel-sec">
      <div class="partner-carausel-inner container">
        <div class="partner-carausel-con">
          <div class="xs-heading" data-aos="fade-left">
            Our Family
          </div>
          <div class="lg-heading" data-aos="fade-right">
            CS Group
          </div>
          <OwlCarousel className="owl-carousel" options {...options}>
            {carouselItems?.map((data, key) => {
              return (
                <div key={key} class="owl-slide">
                  <div class="cs-slide">
                    <div class="partner-con-inner">
                      <img {...data} />
                    </div>
                  </div>
                </div>
              );
            })}
          </OwlCarousel>
        </div>
      </div>
    </section>
  );
}

export default OurPartners;
