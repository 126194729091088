import React from "react";
import "./branches.css";
import images from "../../assets";
import { CampusList, CommonBanner, Footer, Header } from "../../components";

const dataToSend = {
  bannerImg: images["aboutbg"],
  bannerHeading: "Our Branches",
  path: [
    {
      link: "/our-courses",
      name: "Our Courses",
    },
    {
      link: "#",
      name: "Our Branches",
    },
  ],
};

const campusesAllZoneData = [
  {
    campusZone: "NSW Campus",
    campusesData: [
      {
        campusName: "NSW Campus",
        campusAddress: "Unit 121, 14 Lexington DrBella Vista NSW 2153",
        campusEmail: "norwest@cseducation.com.au",
        campusContact: "+61 2 8883 0055 | +61 2 8883 2555",
        campusTimetableLinks: [
          { linkName: "link-to-timetable-1", link: "/" },
          { linkName: "link-to-timetable-1", link: "/" },
          { linkName: "link-to-timetable-1", link: "/" },
          { linkName: "link-to-timetable-1", link: "/" },
        ],
      },
      {
        campusName: "NSW Campus",
        campusAddress: "Unit 121, 14 Lexington DrBella Vista NSW 2153",
        campusEmail: "norwest@cseducation.com.au",
        campusContact: "+61 2 8883 0055 | +61 2 8883 2555",
        campusTimetableLinks: [
          { linkName: "link-to-timetable-1", link: "/" },
          { linkName: "link-to-timetable-1", link: "/" },
          { linkName: "link-to-timetable-1", link: "/" },
          { linkName: "link-to-timetable-1", link: "/" },
        ],
      },
      {
        campusName: "NSW Campus",
        campusAddress: "Unit 121, 14 Lexington DrBella Vista NSW 2153",
        campusEmail: "norwest@cseducation.com.au",
        campusContact: "+61 2 8883 0055 | +61 2 8883 2555",
        campusTimetableLinks: [
          { linkName: "link-to-timetable-1", link: "/" },
          { linkName: "link-to-timetable-1", link: "/" },
          { linkName: "link-to-timetable-1", link: "/" },
          { linkName: "link-to-timetable-1", link: "/" },
        ],
      },
    ],
  },
  {
    campusZone: "VIC Campus",
    campusesData: [
      {
        campusName: "VIC Campus",
        campusAddress: "Address for VIC Campus",
        campusEmail: "vic@cseducation.com.au",
        campusContact: "+61 3 8888 1111",
        campusTimetableLinks: [
          { linkName: "link-to-timetable-1", link: "/" },
          { linkName: "link-to-timetable-1", link: "/" },
        ],
      },
      {
        campusName: "VIC Campus",
        campusAddress: "Address for VIC Campus",
        campusEmail: "vic@cseducation.com.au",
        campusContact: "+61 3 8888 1111",
        campusTimetableLinks: [
          { linkName: "link-to-timetable-1", link: "/" },
          { linkName: "link-to-timetable-1", link: "/" },
        ],
      },
    ],
  },
  // Add more campuses data as needed
];

function OurBranches() {
  return (
    <div class="branches-page">
      <Header />
      <CommonBanner {...dataToSend} />
      <CampusList campusesAllZoneData={campusesAllZoneData} />
      <Footer />
    </div>
  );
}

export default OurBranches;
