import React, { useEffect } from "react";
import images from "../../../assets";
import { Link } from "react-router-dom";
function CommonBanner({ bannerImg, bannerHeading, path }) {
  // console.log("props", bannerImg);
  return (
    <section class="about-ban-sec">
      <div class="about-ban-img">
        <img {...bannerImg} />
      </div>
      <div class="about-ban-inner container">
        <div class="about-ban-content">
          <div class="lg-heading">{bannerHeading}</div>
          {path?.length ? (
            <div class="breadcrumb-hd">
              <span>
                <Link to="/">Home</Link>
                <span>{">"}</span>
              </span>
              {path?.map((data, key) => (
                <span key={key}>
                  {path.length - 1 == key ? (
                    <span>{data.name}</span>
                  ) : (
                    <>
                      <Link to={data.link}>{data.name}</Link>
                      <span>{">"}</span>
                    </>
                  )}
                </span>
              ))}
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </section>
  );
}

export default CommonBanner;

// sample data accept this comoenet
//    path = [
//     {
//       link: "/",
//       name: "Home",
//     }
//   ];
// bannerHeading = "this is heading"
// bannerImg = "image/"
