import React, { useEffect } from "react";
import {
  Header,
  Banner,
  BannerBottom,
  CourseCategory,
  Footer,
  FreeAssessment,
  GetInTouch,
  LearningSystem,
  OurPartners,
  OurVideoSection,
} from "../../components";
// import "./home.css";

function Home() {
  return (
    <>
      <div class="home-page">
        <Header />
        <Banner />
        <BannerBottom />
        <FreeAssessment />
        {/* Not required for now
          <LearningSystem />  */}
        <OurVideoSection />
        <CourseCategory />
        <OurPartners />
        <GetInTouch />
        <Footer />
      </div>
    </>
  );
}

export default Home;
