import React from "react";
import images from "../../../assets";

function OurVision() {
  return (
    <section class="vision-sec">
      <div class="vision-inner container">
        <div class="vision-con-lf">
          <div class="vision-con-lf-img">
            <img {...images["visionbg"]} />
          </div>
        </div>

        <div class="vision-con-rt">
          <div class="galaxy-iconabt">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="150"
              height="155"
              viewBox="0 0 150 155"
              fill="none"
            >
              <path
                d="M58.4502 54.1938L63.3014 53.2265L58.4502 52.2331L61.1888 48.1287L57.0678 50.8736L56.1028 46.0111L55.1377 50.8736L51.0428 48.1287L53.7814 52.2331L48.9302 53.2265L53.7814 54.1938L51.0428 58.2982L55.1377 55.5532L56.1028 60.4157L57.0678 55.5532L61.1888 58.2982L58.4502 54.1938Z"
                fill="#D2D2D2"
              />
              <path
                d="M148.147 80.1535L151.068 79.5522L148.147 78.9771L149.816 76.4935L147.338 78.1405L146.738 75.2125L146.165 78.1405L143.687 76.4935L145.33 78.9771L142.409 79.5522L145.33 80.1535L143.687 82.637L146.165 80.9639L146.738 83.8919L147.338 80.9639L149.816 82.637L148.147 80.1535Z"
                fill="#D2D2D2"
              />
              <path
                d="M174.463 91.97C173.629 90.689 172.246 89.9047 170.916 89.2773C169.038 88.3623 167.082 87.6303 165.126 86.9506C161.083 85.565 156.988 84.6501 152.763 84.0749C151.459 83.8919 151.042 86.1402 152.346 86.3755C155.736 87.0029 159.127 87.8133 162.44 88.7806C164.031 89.2512 165.595 89.7479 167.134 90.323C168.621 90.872 170.264 91.3426 171.647 92.153C172.925 92.9111 173.629 94.0876 173.003 95.5516C172.325 97.1985 170.629 97.9828 169.038 98.4795C165.83 99.473 162.466 100.1 159.179 100.78C145.617 103.604 131.819 105.355 117.996 106.244C90.3485 108.022 62.545 106.505 34.9762 104.152C31.2204 103.839 27.4645 103.499 23.7348 103.081C20.3702 102.689 16.3014 101.774 14.7625 98.3488C13.2498 94.9503 16.0927 91.8131 18.8574 90.2446C21.8308 88.5715 25.0911 87.761 27.7775 85.5912C28.1427 85.2775 27.6993 84.6501 27.282 84.8853C22.5089 87.761 14.3974 88.4669 12.9107 95.0287C12.3369 97.643 13.5367 100.179 15.545 101.826C18.075 103.943 21.3874 104.414 24.5694 104.754C39.671 106.322 54.7986 107.708 69.9784 108.414C100.077 109.773 130.515 108.832 160.092 102.61C162.179 102.166 164.265 101.695 166.352 101.198C168.152 100.78 170.082 100.414 171.725 99.5252C174.411 98.1135 176.289 94.8196 174.463 91.97Z"
                fill="#B9CFD4"
              />
              <path
                d="M127.229 82.4279C127.151 81.0423 126.499 79.7613 125.899 78.5065C125.247 77.147 124.49 75.8138 123.63 74.5589C121.986 72.1538 120.004 70.0101 117.813 68.1017C113.51 64.2848 108.293 61.566 102.738 60.102C91.6267 57.2002 79.9159 60.3112 71.3088 67.8141C66.5097 72.0231 63.1711 77.4608 61.5019 83.6304C61.3715 84.101 62.0496 84.3624 62.2322 83.8919C65.9359 74.0884 74.6473 65.8534 84.6107 62.7163C95.0957 59.3962 106.233 62.0627 114.605 69.069C116.874 70.9774 118.909 73.1995 120.604 75.6569C121.465 76.8856 122.273 78.1666 122.977 79.526C123.682 80.8593 124.151 82.2972 125.064 83.4997C125.899 84.5977 127.281 83.5259 127.229 82.4279Z"
                fill="#B9CFD4"
              />
              <path
                d="M45.0436 68.4938C44.9654 68.2063 44.835 67.971 44.6263 67.7618C44.3133 67.4743 43.9221 67.2913 43.4787 67.2913C43.244 67.2913 43.0353 67.3174 42.8527 67.422C42.6441 67.5004 42.4876 67.605 42.3311 67.7618C42.1746 67.9187 42.0703 68.0755 41.992 68.2847C41.8877 68.4677 41.8355 68.6768 41.8616 68.9121C41.8877 69.069 41.8877 69.1997 41.9138 69.3565C41.992 69.6441 42.1224 69.8794 42.3311 70.0885C42.6441 70.3761 43.0353 70.5591 43.4787 70.5591C43.7135 70.5591 43.9221 70.533 44.1047 70.4284C44.3133 70.35 44.4698 70.2454 44.6263 70.0885C44.7828 69.9317 44.8872 69.7748 44.9654 69.5657C45.0697 69.3827 45.1219 69.1735 45.0958 68.9383L45.0436 68.4938Z"
                fill="#D2D2D2"
              />
              <path
                d="M50.8604 79.5521C49.139 79.5521 49.139 82.2448 50.8604 82.2448C52.5818 82.2448 52.5818 79.5521 50.8604 79.5521Z"
                fill="#D2D2D2"
              />
              <path
                d="M66.4052 58.1151C65.5967 58.1151 65.5967 59.37 66.4052 59.37C67.2138 59.37 67.2138 58.1151 66.4052 58.1151Z"
                fill="#D2D2D2"
              />
              <path
                d="M99.0341 80.9116C97.4431 80.9116 97.4431 83.3952 99.0341 83.3952C100.625 83.3952 100.625 80.9116 99.0341 80.9116Z"
                fill="#D2D2D2"
              />
              <path
                d="M120.813 55.4747C118.961 55.4747 118.961 58.3766 120.813 58.3766C122.665 58.3766 122.665 55.4747 120.813 55.4747Z"
                fill="#D2D2D2"
              />
            </svg>
          </div>
          <div class="lg-heading" data-aos="fade-right">
            Our <span class="red-text">Vision</span>
          </div>
          <div class="text-para">
            <p>
              CS Education brings together everything needed for a Total
              Education Solution. We aims to offer an educational solution
              providing everything necessary to equip students with an education
              equal to the best in the world in the 21st century. In order to do
              this we will select the best systems, the most helpful books, and
              the latest ideas from educators all around the world, and adapt
              them where necessary for use in Australia.
            </p>
            <p>
              It is our intention to bring something new to education in
              Australia, to provide a platform on which we will develop new
              education systems and methods, and thus to advance the quality of
              education so that our students have a real advantage.
            </p>
            <p>
              Whilst we expect our students to excel in the Australia education
              system, our aim goes far beyond this. We intend to provide here in
              Australia a system of education in line with the best in the
              world.
            </p>
            <p>
              You will find that the different parts of our website are
              complementary; they work together to ensure the integrity of our
              system.
              <br />
              We do not just recommend books or advise tuition – CS Education
              brings together everything needed for a Total Education Solution.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default OurVision;
