import React from "react";
import images from "../../../assets";
const CampusBox = ({ campusData }) => {
  const {
    campusName,
    campusAddress,
    campusEmail,
    campusContact,
    campusTimetableLinks,
  } = campusData;

  return (
    <div className="campus-box">
      <div className="row">
        <div className="col-md-4">
          <div className="campus-map">
            <img {...images["campus-map"]} />
          </div>
        </div>

        <div className="col-md-4">
          <div className="campus-middle-content">
            <div className="campus-hd">{campusName}</div>
            <div className="campusdt c-email">
              <a href={`mailto:${campusEmail}`}>
                <i className="fa-regular fa-envelope"></i>
                {campusEmail}
              </a>
            </div>
            <div className="campusdt c-contact">
              <a href={`tel:${campusContact}`}>
                <i className="fa-solid fa-phone"></i>
                {campusContact}
              </a>
            </div>
            <div className="campusdt c-address">
              <a href="">
                <i className="fa-solid fa-location-dot"></i>
                {campusAddress}
              </a>
            </div>
            <div className="sap-black-btn">
              <a href="">
                <i className="fa-solid fa-phone"></i>
                Inquiry Now!
              </a>
            </div>
          </div>
        </div>
        <div className="col-md-4 brder-rt">
          <div className="cc-detail">
            {campusTimetableLinks.map((link, index) => (
              <div key={index} className="sap-light-btn">
                <a href={link}>{`Timetable ${index + 1}`}</a>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CampusBox;
