import React from "react";

function FreeAssessment() {
  return (
    <>
      <section class="free-assessment-sec">
        <div class="free-assess-inner container">
          <div class="free-assess-lf">
            <div class="lg-heading">Take a Free Assessment</div>
            <div class="assess-para">
              Primary Students (Year 2 to Year 6) for Maths and English
            </div>
          </div>

          <div class="free-assess-rt">
            <div class="sap-red-btn">
              <a target="_blank" href="http://65.1.219.190:3002/loginFreeAssesment">
                Free Assessment
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default FreeAssessment;
