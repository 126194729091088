import React from "react";
import "./blogs.css";
import { BlogSection, CommonBanner, Footer, Header } from "../../components";
import images from "../../assets";

const dataToSend = {
  bannerImg: images["aboutbg"],
  bannerHeading: "Blogs",
  path: [
    {
      link: "#",
      name: "Blogs",
    },
  ],
};

function Blogs() {
  return (
    <div class="blog-page">
      <Header />
      <CommonBanner {...dataToSend} />
      <BlogSection />
      <Footer />
    </div>
  );
}

export default Blogs;
