import React from "react";
import images from "../../../assets";
import { Link } from "react-router-dom";
function SearchSimilarSIdeBar() {
  return (
    <section class="sidebar-courses-sec">
      <div class="stardetail-icon">
        <img {...images["star-icon"]} />
      </div>
      <div class="sidebar-inner">
        <div class="title-hd" data-aos="fade-left">
          Search courses
        </div>
        <div class="search-box">
          <input type="search" class="form-control" />
        </div>
        <div class="sim-courses">
          <div class="sim-top-bar">
            <div class="title-hd">Similar Courses</div>
            <div class="view-courses">
              <Link to="/our-courses">View all</Link>
            </div>
          </div>

          <div class="sim-cours-box">
            <div class="sim-courses-list">
              <div class="img-lf">
                <img {...images["sim-course"]} />
              </div>
              <div class="sim-cours-hd">
                <Link to="/our-branches">Castle Hill Campus</Link>
                <p class="sim-course-dt">16 September 2022</p>
              </div>
            </div>

            <div class="sim-courses-list">
              <div class="img-lf">
                <img {...images["sim-course"]} />
              </div>
              <div class="sim-cours-hd">
                <Link to="/our-branches">Strathfield Campus</Link>
                <p class="sim-course-dt">16 September 2022</p>
              </div>
            </div>

            <div class="sim-courses-list">
              <div class="img-lf">
                <img {...images["sim-course"]} />
              </div>
              <div class="sim-cours-hd">
                <Link to="/our-branches">T3 Enroll Now!</Link>
                <p class="sim-course-dt">16 September 2022</p>
              </div>
            </div>
          </div>
        </div>

        <div class="trial-courses">
          <div class="title-hd">Trial Test</div>
          <div class="strategy-list list-course">
            <ul>
              <li>
                <Link to="/course-detail">OC Trial Test</Link>
              </li>
              <li>
                <Link to="/course-detail">Selective Trial Test</Link>
              </li>
            </ul>
          </div>
        </div>

        <div class="comprehensive-course">
          <div class="title-hd">Comprehensive Course</div>
          <div class="sim-cours-box">
            <div class="sim-courses-list">
              <div class="img-lf">
                <img {...images["writing"]} />
              </div>
              <div class="sim-cours-hd">
                <Link to="/course-detail">Writing</Link>
              </div>
            </div>

            <div class="sim-courses-list">
              <div class="img-lf">
                <img {...images["reading"]} />
              </div>
              <div class="sim-cours-hd">
                <Link to="/course-detail">English | Reading</Link>
              </div>
            </div>

            <div class="sim-courses-list">
              <div class="img-lf">
                <img {...images["maths"]} />
              </div>
              <div class="sim-cours-hd">
                <Link to="/course-detail">Mathematics</Link>
              </div>
            </div>

            <div class="sim-courses-list">
              <div class="img-lf">
                <img {...images["skill"]} />
              </div>
              <div class="sim-cours-hd">
                <Link to="/course-detail">
                  Thinking Skills | General Ability
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default SearchSimilarSIdeBar;
