import React from "react";
import {
  Header,
  CommonBanner,
  EducationTimeline,
  Footer,
  HistoryCarauselTimeline,
  MessageSection,
} from "../../components";
import "./history.css";
import images from "../../assets";
const dataToSend = {
  bannerImg: images["aboutbg"],
  bannerHeading: "Our History",
  path: [
    {
      link: "/",
      name: "Our History",
    },
  ],
};

function OurHistory() {
  return (
    <div class="history-page">
      <Header />
      <CommonBanner {...dataToSend} />
      <MessageSection />
      <EducationTimeline />
      <HistoryCarauselTimeline />
      <Footer />
    </div>
  );
}

export default OurHistory;
