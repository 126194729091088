import React from "react";
import "./ourCourses.css";
import {
  CommonBanner,
   CourseCategory,
  Footer,
  FreeAssessment,
  Header,
} from "../../components";
import images from "../../assets";
const dataToSend = {
  bannerImg: images["aboutbg"],
  bannerHeading: "Our Courses",
  path: [
    {
      link: "/",
      name: "Our Courses",
    },
  ],
};

function OurCourses() {
  return (
    <div class="courses-page">
      <Header />
      <CommonBanner {...dataToSend} />
      <CourseCategory />
      <FreeAssessment />
      <Footer />
    </div>
  );
}

export default OurCourses;
